import { Flex, StackProps, VStack } from '@chakra-ui/react';
import { motion, useAnimationControls, Transition } from 'framer-motion';
import { FC, useEffect } from 'react';

const MotionFlex = motion(Flex);

const transitionProps: Transition = {
  stiffness: 400,
  type: 'spring',
  damping: 60,
  mass: 3,
};

interface ITrackProps extends StackProps {
  activeItem: number;
  positions: Array<number>;
}

export const Track: FC<ITrackProps> = ({ activeItem, positions, children }) => {
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start({
      x: positions[activeItem],
      transition: {
        ...transitionProps,
      },
    });
  }, [activeItem, controls, positions]);

  return (
    <VStack spacing={5} alignItems="stretch">
      <MotionFlex animate={controls} minWidth="min-content" flexWrap="nowrap">
        {children}
      </MotionFlex>
    </VStack>
  );
};
