import { chakra, Heading, HTMLChakraProps, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface INoResultsProps extends HTMLChakraProps<'div'> {
  title?: string;
  subtitle?: string;
  subtitleSlot?: ReactNode;
}

export const NoResults: FC<INoResultsProps> = ({ title, subtitle, subtitleSlot, ...rest }) => {
  return (
    <chakra.div {...rest}>
      <Heading fontSize={['2xl', null, '3xl']}>{title}</Heading>
      {subtitleSlot ? (
        subtitleSlot
      ) : (
        <Text mt={3} fontSize="md">
          {subtitle}
        </Text>
      )}
    </chakra.div>
  );
};
