import { useSafeLayoutEffect } from '@chakra-ui/react';
import { useState, useCallback } from 'react';

const debounce = (limit: number, callback: (e?: Event) => void): ((...args) => void) => {
  let timeoutId;

  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(callback, limit, args);
  };
};

interface IDimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

const getDimensionObject = (node: HTMLInputElement): IDimensionObject => {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  };
};

export const useBounding = (limit?: number) => {
  const [dimensions, setDimensions] = useState({});
  const [pack, setPack] = useState(null);

  const ref = useCallback((node) => {
    setPack(node);
  }, []);

  useSafeLayoutEffect(() => {
    if ('undefined' !== typeof window && pack) {
      const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(pack)));

      measure();

      const listener = debounce(limit ? limit : 100, measure);

      window.addEventListener('resize', listener);
      window.addEventListener('scroll', listener);
      return () => {
        window.removeEventListener('resize', listener);
        window.removeEventListener('scroll', listener);
      };
    }
  }, [pack, limit]);

  return [ref, dimensions, pack];
};
