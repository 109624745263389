import { Box, BoxProps } from '@chakra-ui/react';
import { FC, Fragment, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';

import { Slider } from '@/components/ui/shared/carousel/components/Slider';
import { Track } from '@/components/ui/shared/carousel/components/Track';

interface ICarouselProps extends BoxProps {
  buttonPrev?: (props) => ReactElement;
  buttonNext?: (props) => ReactElement;
  data: Array<any>;
  widthCard?: number;
  itemLoader: ReactNode;
  isLoadingMore?: boolean;
  isReachingEnd?: boolean;
  allowInfinite?: boolean;
  render(item: any): ReactNode;
  onLoadMore?(): void;
}

const getDataCarousel = (sliderWidth, widthCard) => {
  if (!sliderWidth) {
    return { newGap: 0, countConstraint: 0 };
  }

  const countConstraint = Math.floor(sliderWidth / widthCard);
  const newGap = Math.floor((sliderWidth - countConstraint * widthCard) / countConstraint);

  return { newGap, countConstraint };
};

export const Carousel: FC<ICarouselProps> = ({
  data,
  render,
  widthCard = 288,
  itemLoader,
  isLoadingMore = false,
  isReachingEnd = true,
  allowInfinite = false,
  onLoadMore,
  buttonPrev,
  buttonNext,
  ...rest
}) => {
  const [sliderWidth, setSliderWidth] = useState(0);
  const [activeItem, setActiveItem] = useState(0);

  const initSliderWidth = useCallback((width) => setSliderWidth(width), []);

  const [gap, constraint] = useMemo(() => {
    const { newGap, countConstraint } = getDataCarousel(sliderWidth, widthCard);

    return [newGap || 12, countConstraint || 0];
  }, [sliderWidth, widthCard]);

  const positions = useMemo(() => data.map((_, index) => -Math.abs((widthCard + gap) * index)), [gap, data, widthCard]);

  const sliderProps = {
    initSliderWidth,
    setActiveItem,
    isLoadingMore,
    isReachingEnd,
    activeItem,
    constraint,
    allowInfinite,
    onLoadMore,
    positions,
    buttonPrev,
    buttonNext,
  };

  const trackProps = {
    activeItem,
    positions,
  };

  return (
    <Box {...rest}>
      <Slider {...sliderProps}>
        <Track {...trackProps}>
          {!data.length && isLoadingMore
            ? Array.from({ length: 4 }, (_, index) => (
                <Box key={index} mx={`${gap / 2}px`}>
                  {itemLoader}
                </Box>
              ))
            : data.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Box mx={`${gap / 2}px`}>{render(item)}</Box>
                    {index === data.length - 1 && isLoadingMore ? <Box mx={`${gap / 2}px`}>{itemLoader}</Box> : null}
                  </Fragment>
                );
              })}
        </Track>
      </Slider>
    </Box>
  );
};
