import { Box, Flex, FlexProps, useSafeLayoutEffect } from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';
import { FC, ReactElement } from 'react';

import LeftIcon from '@/assets/icons/ic-left.svg';
import RightIcon from '@/assets/icons/ic-right.svg';

import { SliderButton } from '@/components/ui/shared/carousel/components/SliderButton';
import { useBounding } from '@/hooks/useBounding';

interface ISliderProps extends FlexProps {
  buttonPrev?: (props) => ReactElement;
  buttonNext?: (props) => ReactElement;
  positions: Array<number>;
  activeItem: number;
  constraint: number;
  isReachingEnd: boolean;
  isLoadingMore: boolean;
  allowInfinite: boolean;
  onLoadMore(): void;
  initSliderWidth(number): void;
  setActiveItem(number): void;
}

export const Slider: FC<ISliderProps> = ({
  initSliderWidth,
  isLoadingMore,
  isReachingEnd,
  setActiveItem,
  onLoadMore,
  allowInfinite,
  activeItem,
  constraint,
  positions,
  buttonPrev,
  buttonNext,
  children,
}) => {
  const [ref, { width }] = useBounding();

  useSafeLayoutEffect(() => initSliderWidth(Math.round(width)), [width, initSliderWidth]);

  const handleDecrementClick = () => {
    if (activeItem !== 0) {
      setActiveItem((prev) => prev - 1);
    } else if (allowInfinite) {
      setActiveItem(positions.length - constraint);
    }
  };

  const handleIncrementClick = () => {
    const totalLength = positions.length - constraint;

    if (activeItem === totalLength) {
      if (!isReachingEnd) {
        onLoadMore?.();
      } else if (allowInfinite) {
        setActiveItem(0);
        return;
      }
    }

    setActiveItem((prev) => prev + 1);
  };

  const isShowLeftArrow = allowInfinite || activeItem !== positions.length - positions.length;
  const isShowRightArrow =
    allowInfinite ||
    (positions.length >= constraint && (!isReachingEnd || !(activeItem === positions.length - constraint)));

  return (
    <Flex position="relative" className="slider">
      {isShowLeftArrow && (
        <Flex minW="40px" h="100%" left="0" position="absolute" transition="background 0.5s" zIndex={2}>
          {runIfFn(buttonPrev, {
            onClick: handleDecrementClick,
            disabled: isLoadingMore,
            'aria-label': 'left',
          })}

          {!buttonPrev && (
            <SliderButton onClick={handleDecrementClick} aria-label="left" isDisabled={isLoadingMore}>
              <LeftIcon color="white" />
            </SliderButton>
          )}
        </Flex>
      )}
      {isShowRightArrow && (
        <Flex minW="40px" h="100%" right="0" position="absolute" transition="background 0.5s" zIndex={2}>
          {runIfFn(buttonNext, {
            onClick: handleIncrementClick,
            disabled: isLoadingMore,
            'aria-label': 'right',
          })}
          {!buttonPrev && (
            <SliderButton onClick={handleIncrementClick} aria-label="right" isDisabled={isLoadingMore}>
              <RightIcon color="white" />
            </SliderButton>
          )}
        </Flex>
      )}
      <Box w="100%" ref={ref} position="relative" overflow="hidden" zIndex={1}>
        {children}
      </Box>
    </Flex>
  );
};
