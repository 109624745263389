import { Box, BoxProps, Flex, Heading, Icon, Image, useBreakpointValue } from '@chakra-ui/react';
import { FC, Fragment, useState } from 'react';

import LockIconOpen from '@/assets/icons/ic-lock-open.svg';
import LockIcon from '@/assets/icons/ic-lock.svg';

import { FlippedLectureCard } from '@/components/ui/shared/lecture/FlippingLectureCard/components/FlippedLectureCard';
import { LectureAuthorsText } from '@/components/ui/shared/lecture/FlippingLectureCard/components/LectureAuthorsText';
import { ThinLecture } from '@/resources/ThinLecture';
import { LECTURE_CARD_IMAGE_FALLBACK } from '@/utils/constants';

interface IFlippingLectureCardProps extends BoxProps {
  lecture: ThinLecture;
}
export const FlippingLectureCard: FC<IFlippingLectureCardProps> = ({ lecture, ...rest }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const screenSize = useBreakpointValue({ base: 'sm', md: 'md' }, { ssr: true, fallback: 'md' });

  const isMobile = screenSize === 'sm';

  return (
    <Box
      onMouseEnter={() => !isMobile && setIsFlipped(true)}
      onMouseLeave={() => !isMobile && setIsFlipped(false)}
      onClick={() => isMobile && setIsFlipped((f) => !f)}
      backgroundColor="white"
      overflow="hidden"
      borderRadius="8"
      w={{ base: '335px', lg: '389.3px' }}
      h={{ base: '294px', md: '320px' }}
      {...rest}
    >
      {!isFlipped && (
        <Fragment>
          <Image objectFit="cover" src={lecture.posterThumbnailUrl || LECTURE_CARD_IMAGE_FALLBACK} w="full" h="219px" />
          <Box h={{ base: '54px', md: '74px' }} overflowY="hidden" color="gray.800" px="4" mt="4">
            <Heading mb="1" color="black" lineHeight={6} noOfLines={2} fontSize="m">
              {lecture.title}
            </Heading>
            <Flex justifyContent="space-between" alignItems="center">
              <LectureAuthorsText profiles={lecture.sortedProfiles} />
              <Icon
                as={lecture?.lectureAccessPermission ? LockIcon : LockIconOpen}
                h={6}
                w={6}
                color={lecture?.lectureAccessPermission ? 'gray.500' : 'teal.500'}
              />
            </Flex>
          </Box>
        </Fragment>
      )}
      {isFlipped && <FlippedLectureCard lecture={lecture} />}
    </Box>
  );
};
