import { IconButton } from '@chakra-ui/react';

export const SliderButton = (props) => {
  return (
    <IconButton
      borderRadius="none"
      variant="link"
      minW="100%"
      h="full"
      sx={{
        '.slider &': {
          bg: 'rgba(0, 0, 0, 0.15)',
        },
        '.slider:hover &': {
          bg: 'rgba(0, 0, 0, 0.5)',
        },
      }}
      {...props}
    />
  );
};
